import React, { useEffect, useMemo } from 'react';
import { Button } from "@mui/material";
import * as SurveyReact from "survey-react-ui";
import * as SurveyPDF from "survey-pdf";
import Inputmask from 'inputmask';

// @ts-ignore
import * as widgets from "surveyjs-widgets";
import * as SurveyCore from "survey-core";

//Import Survey localization
import "survey-core/survey.i18n.js";
//Import Survey Creator localization
import "survey-core/modern.min.css";
import './styles.css';
import { SessionStore } from "../../lib/SessionStore";

Inputmask.extendAliases({
  cad: {
    prefix: "$ ",
    groupSeparator: ",",
    alias: "numeric",
    placeholder: "0",
    allowMinus: false,
    digits: 0,
    digitsOptional: false,
    clearMaskOnLostFocus: false
  }
});

SurveyCore
  .StylesManager
  .applyTheme("modern");

widgets.inputmask(SurveyCore);

interface Props {
  model: any;
  data: Object;
  clientId: string;
  onSave: (data: object) => any;
  onComplete: (data: object) => any;
  onUpdateWip: (data: object) => any;
}

const SurveyRendered: React.FC<Props> = (props: Props) => {
  const { model, data, clientId, onSave, onComplete, onUpdateWip } = props;
  const survey = useMemo(() => {
    const _s =new SurveyCore.Model(model)
    _s.data = data;
    _s.surveyShowDataSaving = true;
    if (SessionStore.getResponseState(clientId) === 'preview') {
      _s.showPreview();
    }
    if (SessionStore.getResponseState(clientId) === 'completed') {
      _s.setCompleted();
    }
    if (SessionStore.getResponsePage(clientId)) {
      _s.currentPageNo = parseInt(SessionStore.getResponsePage(clientId) as string);
    }
    return _s
  }, [model, data, clientId]);

  // survey.pageNextText = "Save & Next";
  // survey.pagePrevText = "Save & Previous";

  useEffect(() => {
    survey
      .onValueChanged
      .add(function (sender: any) {
        onUpdateWip(sender.data);
      });
    survey
      .onCurrentPageChanged
      .add((sender: any) => {
        SessionStore.setResponseState(clientId, String(survey.state))
        SessionStore.setResponsePage(clientId, survey.currentPageNo)
        onSave(sender.data);
      });
    survey
      .onComplete
      .add((sender: any) => {
        SessionStore.setResponseState(clientId, String(survey.state))
        SessionStore.clearResponsePage(clientId)
        onComplete(sender.data);
      });
  }, [survey, onSave, onComplete, clientId, onUpdateWip]);

  if (!model) {
    return null;
  }

  const downloadPdf = () => {
    const { title } = model;
    model.pages.forEach((page: any) => page.elements?.forEach((element: any) => {
      element.visible = true;
      element.visibleIf = undefined;
    }));

    const surveyPDF = new SurveyPDF.SurveyPDF(
      model,
      {
        orientation: 'p', format: 'letter',
        commercial: true,
        fontName: 'Helvetica', fontSize: 10,
        readonlyRenderAs: 'auto',
        margins: {
          top: 20,
          bot: 10,
          left: 20,
          right: 20
        }
      }
    );

    surveyPDF.getAllQuestions().forEach(function (question) {
      question.visible = true;
    });
    surveyPDF.mode = 'edit';

    title && surveyPDF.save(`${title}.pdf`);
  };

  return (
    <div className="survey-container">
      {survey.currentPageNo === 0 && survey.state === 'running'?
        <Button onClick={() => downloadPdf()}>Download a blank PDF version of this form.</Button>
        : <></>}
      <SurveyReact.Survey model={survey}/>
    </div>
  );
};

// function propsAreEqual(prevProps: any, nextProps: any) {
//   return true;
// }
//
// export const MemoizedSurveyRendered = React.memo(SurveyRendered, propsAreEqual);
export default SurveyRendered;
