import { gql } from '@apollo/client';

export const QUERY_GET_SURVEY_DATA = gql`
    query getSurveyDataByShortname($shortname: String) {
        getSurveys(filter: {shortname: $shortname}) {
            id
            shortname
            title
            description
            savable
            active
            inactiveMessage
            publishedVersion {
                id
                name
                config
            }
        }
    }
`;

export const QUERY_GET_RESPONSE = gql`
    query getResponse($id: ID!) {
        getResponse(id: $id) {
            id
            user
            isCompleted
            survey {
                id
            }
            surveyVersion {
                id
            }
            data
            updatedAt
        }
    }
`;

export const MUTATION_CREATE_RESPONSE = gql`
    mutation createResponse($input: ResponseInput!) {
        createResponse(input: $input) {
            id
        }
    }
`;

export const MUTATION_UPDATE_RESPONSE = gql`
    mutation updateResponse($id: ID!, $input: ResponseInput) {
        updateResponse(id: $id, input: $input) {
            id
            user
            isCompleted
            survey {
                id
            }
            surveyVersion {
                id
            }
            data
            updatedAt
        }
    }
`;