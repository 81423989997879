import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import _ from 'lodash';

interface Props {
  settingType: string;
  backLabel: string;
  handleNavClick: (tab: string) => void;
  activeTab?: string;
  hideBackArrow?: boolean;
  backUrl?: string;
}

const SettingNavigation = (props: Props) => {
  const {
    settingType,
    backLabel,
    handleNavClick,
    hideBackArrow,
    backUrl,
  } = props;
  const navigate = useNavigate();

  const [panel, setPanel] = useState<string | null>('farms');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const val = _.get(event.target, 'value');
    if (val) {
      setPanel(val);
      handleNavClick(val);
    }
  };

  /* Load the settins buttons for a particular object */
  const getSettingNavButtons = (settingType: string) => {
    switch (settingType) {
      default:
        return null;
    }
  };

  return (
    <Box>
      {!(backLabel === '') && (
        <Box
          alignContent="center"
          alignItems="center"
          height="50px"
          className="setting-back-container"
        >
          {!hideBackArrow && (
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => (backUrl ? navigate(backUrl) : navigate(-1))}
              className="clickable-icon"
            >
              <circle cx="14.5" cy="14.5" r="14.5" fill="#EEEEEE" />
              <path
                d="M22 14H9.83L15.42 8.41L14 7L6 15L14 23L15.41 21.59L9.83 16H22V14Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          )}
          <Typography
            display="inline"
            variant="h5"
            color="#1976D2"
            sx={{
              ml: 1,
            }}
          >
            {backLabel}
          </Typography>
        </Box>
      )}

      <ToggleButtonGroup
        color="primary"
        size="small"
        className="setting-nav-buttons"
        value={panel}
        onChange={(ev) => handleClick(ev)}
        exclusive
        sx={{
          p: 3,
          pl: 0,
          pt: 0,
          m: 'auto',
        }}
      >
        {getSettingNavButtons(settingType)}
      </ToggleButtonGroup>
    </Box>
  );
};

export default SettingNavigation;
