import * as React from 'react';
import Box from '@mui/material/Box';

import './styles.css';
import HeadingBar from "../../HeadingBar";
import UserSubmissions from "../../UserSubmissions/UserSubmissions";

const AdminVersionsPage: React.FC = () => {
  return (
    <React.Fragment>
      <Box
        sx={{ color: 'primary.main' }}
        maxWidth="lg"
        className="landing-page"
      >
        <HeadingBar
          text="My Submissions"
        />
        <Box className="landing-page-context">
          <UserSubmissions/>
        </Box>

      </Box>
    </React.Fragment>
  );
};

export default AdminVersionsPage;
