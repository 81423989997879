import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    tileLink: React.CSSProperties;
    tileLinkWhiteText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tileLink?: React.CSSProperties;
    tileLinkWhiteText?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tileLink: true;
    tileLinkWhiteText: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#00456b',
    },
    secondary: {
      main: '#009999',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#004266',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: ['PublicSans'].join(','),
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '1.9',
      letterSpacing: '.015em',
      color: '#078ca9',
    },
    h1: {
      fontWeight: 700,
      fontSize: '2em',
      lineHeight: '1.2',
      letterSpacing: '-.015em',
      color: '#078ca9',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.5em',
      lineHeight: '1.2',
      letterSpacing: '-.015em',
      color: '#078ca9',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.3em',
      lineHeight: '1.1',
      letterSpacing: '-.015em',
      color: '#078ca9',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1em',
      lineHeight: '1.1',
      letterSpacing: '-.015em',
      color: '#078ca9',
    },
    h5: {
      fontWeight: 500,
      fontSize: '.8em',
      lineHeight: '1.2',
      letterSpacing: '-.015em',
      color: '#078ca9',
    },
    h6: {
      fontWeight: 500,
      fontSize: '.7em',
      lineHeight: '1.2',
      letterSpacing: '-.015em',
    },
    tileLink: {
      color: '#078ca9',
      fontSize: '22px',
      fontWeight: '800',
      textDecorationLine: 'none',
      lineHeight: '1.9rem',
      textDecoration: 'none',
    },
    tileLinkWhiteText: {
      color: 'white',
      fontSize: '22px',
      fontWeight: '800',
      textDecorationLine: 'none',
      lineHeight: '1.9rem',
    },
  },
});

export default theme;
