import * as React from 'react';

import './styles.css';
import { Survey } from '../../generated/graphql';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Label as LabelIcon } from "@mui/icons-material";

interface Props {
  data: Survey[];
}

const SurveyList: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const navigate = useNavigate();
  if (!data) {
    return null;
  }

  const newResponse = (shortname: string) => {
    navigate(`/form/${shortname}`);
  };

  return <List>
    {
      data?.filter(x => x.publishedVersion).map(survey => {
        return (
          <ListItem disablePadding key={survey.id}>
            <ListItemButton>
              <ListItemIcon>
                <LabelIcon/>
              </ListItemIcon>
              <ListItemText primary={survey.title}
                            onClick={() => newResponse(survey?.shortname as string)}
              />
            </ListItemButton>
          </ListItem>
        );
      })
    }
  </List>;
};

export default SurveyList;
