import { gql } from '@apollo/client';


export const MUTATION_CREATE_NEW_SURVEY_ADMIN = gql`
    mutation createSurvey($input: SurveyInput!) {
        createSurvey(input: $input) {
            id
            shortname
        }
    }
`;