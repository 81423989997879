import { gql } from '@apollo/client';

export const MUTATION_UPDATE_SELF  = gql`
    mutation updateSelf($id: ID, $input: UserInput) {
        updateSelf(id: $id, input: $input) {
            id
            name
            email
        }
    }
`;

export const QUERY_GET_CURRENT_USER = gql`
    query getCurrentUser {
        getCurrentUser {
            id
            name
            email
        }
    }
`;
