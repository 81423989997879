import { gql } from '@apollo/client';



export const QUERY_GET_SURVEY = gql`
    query getSurvey($id: ID) {
        getSurvey(id: $id) {
            id
            title
            shortname
            description
        }
    }
`;


export const QUERY_GET_ALL_RESPONSES = gql`
    query getResponsesMetadata($filter: ResponseInput) {
        getResponsesMetadata(filter: $filter) {
            id
            user
            isCompleted
            organization
            survey {
                id
                shortname
            }
            surveyVersion {
                id
                name
            }
            updatedAt
            createdAt
        }
    }
`;

export const QUERY_GET_RESPONSE_FOR_DOWNLOAD = gql`
    query getResponseForDownload($id: ID!) {
        getResponse(id: $id) {
            id
            user
            isCompleted
            survey {
                id
            }
            surveyVersion {
                id
                config
            }
            data
            updatedAt
        }
    }
`;

export const MUTATION_EXPORT_ALL_CSV = gql`
    mutation exportAllToCSV($filter: ResponseInput!) {
        exportAllToCSV(filter: $filter)
    }
`;
