import React from 'react';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import DividerWithText from '../DividerWithText';

import './styles.css';

interface Props {
  warningMessage?: string;
  onSuccess: () => any;
  onCancel: () => any;
}

export const ExitDialog: React.FC<Props> = ({ warningMessage, onSuccess, onCancel}) => {

  return (
    <Box minWidth="lg" sx={{ flexGrow: 1, width: 'auto' }}>
      <Paper sx={{ p: '1em' }}>
        <Stack spacing={3}>
          <Typography variant="h3">Are you sure you want to exit?</Typography>
          {warningMessage &&
              <DividerWithText>
                  <span className="red-text">{warningMessage}</span>
              </DividerWithText>
          }
          <div>
            <Button className="float-right" onClick={() => onSuccess()}>
              <Typography variant="h3">Exit</Typography>
            </Button>
            <Button className="float-left" onClick={() => onCancel()} >
              <Typography variant="h3">Return to form</Typography>
            </Button>
          </div>
        </Stack>
      </Paper>
    </Box>
  );
};

