import React from 'react';
import { styled } from '@mui/material';

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Divider = styled('div')(() => ({
  borderBottom: '2px solid lightgray',
  width: '100%',
}));

const Content = styled('span')(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  fontWeight: 500,
  fontSize: 18,
  color: 'gray',
  whiteSpace: 'nowrap',
}));

const DividerWithText: React.FC = ({ children }) => {
  return (
    <Container>
      <Divider />
      <Content>{children}</Content>
      <Divider />
    </Container>
  );
};
export default DividerWithText;
