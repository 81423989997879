import React from 'react';
import { useGetAllSurveysAdminQuery, Survey } from '../../generated/graphql';
import AdminSurveyList from './AdminSurveyList';

interface Props {
}

const AdminSurveyListContainer = (props: Props) => {

  const { data, error, loading, refetch } = useGetAllSurveysAdminQuery()

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  return data ? <AdminSurveyList data={data?.getSurveys as Survey[]} refetch={refetch} /> : <></>;
};

export default AdminSurveyListContainer;
