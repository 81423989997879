import * as React from 'react';

import '../../ApplicationPages/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../../lib/UserContext";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GET_RESPONSE } from "../../SurveyRendered/query";
import { SessionStore } from "../../../lib/SessionStore";
import { AlertDialog } from "../../AlertDialog";

interface Props {
}

const ResumePage: React.FC = (props: Props) => {
  const [content, setContent] = useState(<CircularProgress/>);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const user = useContext(UserContext);

  const shortname = queryParams.get('shortname');
  const email = queryParams.get('email');
  const responseId = queryParams.get('responseid');

  const { data: dataResponse, loading: loadingResponse } = useQuery(QUERY_GET_RESPONSE, {
    variables: {
      id: responseId
    }
  });

  useEffect(() => {
    if (!email || !shortname || !responseId)
      navigate('/');

    if (!user.userLoading && !user.user) {
      navigate(`/login?email=${email}&resume=${responseId}&shortname=${shortname}`);
    }

    if (user.userLoading) {
      setContent(<CircularProgress/>);
      return;
    }

    if (user.user?.email?.toLowerCase() === email?.toLowerCase()) {
      SessionStore.setAuthedResponseId(shortname as string, responseId as string);
      if (!loadingResponse && dataResponse) {
        if (dataResponse.getResponse.isCompleted)
          navigate(`/submission/${responseId}`);
        else
          navigate(`/form/${shortname}`);
      } else {
        setContent(<CircularProgress/>);
        return;
      }
    }
    if (user.user?.email?.toLowerCase() !== email?.toLowerCase()) {
      setContent(<AlertDialog
        title={"Authentication error"}
        msg={`You are logged in as ${user.user?.email}, but the response belongs to ${email}. Please log out and try again.`}
        isOpen={true}
        onClose={() => navigate('/submissions')}
      />);
    } else {
      setContent(<AlertDialog
        title={"There was an error loading your response."}
        msg={`Contact programs@perennia.ca for additional assistance. Please include this reference number: ${responseId}`}
        isOpen={true}
        onClose={() => navigate('/')}
      />);
    }
  }, [loadingResponse, dataResponse, navigate, email, responseId, shortname, user]);

  return (
    <Dialog open={true}>
      <Box sx={{ p: '3em' }}>
        {content}
      </Box>
    </Dialog>
  );
};

export default ResumePage;
