import React, { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Response, Survey } from "../../generated/graphql";
import {
  Download as DownloadIcon,
  PictureAsPdf as PdfIcon,
  Preview as PreviewIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  Backdrop,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLazyQuery } from "@apollo/client";

import "./styles.css";
import "../../styles/table.css";
import { QUERY_GET_RESPONSE_FOR_DOWNLOAD } from "./query";
// import { downloadCsv } from "./Exporter";
import { exportPDF } from "./Pdf";

interface Props {
  survey: Survey;
  data: Response[];
  surveyId: string;
  exportAllToCSV: (args: any) => void;
}

const AdminResponseList: React.FC<Props> = (props: Props) => {
  const { data, survey, exportAllToCSV, surveyId } = props;
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);

  const [getResponsePdf, { loading: loadingPDF }] = useLazyQuery(
    QUERY_GET_RESPONSE_FOR_DOWNLOAD,
    {
      onCompleted: (d) => {
        exportPDF(d.getResponse);
      },
    }
  );

  const [getResponseCsv, { loading: loadingCsv }] = useLazyQuery(
    QUERY_GET_RESPONSE_FOR_DOWNLOAD,
    {
      onCompleted: (d) => {
        // downloadCsv(d.getResponse);
      },
    }
  );

  const downloadPdf = useCallback(
    (response: Response) => {
      getResponsePdf({
        variables: { id: response.id },
      });
    },
    [getResponsePdf]
  );

  const handleCsv = useCallback(
    (response: Response) => {
      getResponseCsv({
        variables: { id: response.id },
      });
    },
    [getResponseCsv]
  );

  const flexNoneCol = 0;
  const flexNarrowCol = 0.2;
  const flexMediumCol = 0.4;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "user",
        headerName: "E-mail",
        minWidth: 85,
        flex: flexMediumCol,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "organization",
        headerName: "Organization",
        minWidth: 70,
        flex: flexMediumCol,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          );
        },
      },
      {
        field: "isCompleted",
        headerName: "Status",
        minWidth: 55,
        flex: flexNarrowCol,
        valueGetter: ({ row }) => (row?.isCompleted ? "Submitted" : "Draft"),
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 90,
        flex: 0,
        filterable: false,
        sortable: false,
        renderCell: ({ row: response }) => {
          return (
            <>
              <Tooltip title={"Download PDF"}>
                <PdfIcon onClick={() => downloadPdf(response)} />
              </Tooltip>
              <Tooltip title={"Preview"}>
                <PreviewIcon
                  onClick={() => navigate(`/admin/response/${response.id}`)}
                />
              </Tooltip>
              <Tooltip title={"Export to CSV"}>
                <DownloadIcon onClick={() => handleCsv(response as Response)} />
              </Tooltip>
            </>
          );
        },
      },
      {
        field: "id",
        headerName: "Response ID",
        minWidth: 30,
        flex: flexNarrowCol,
      },
      {
        field: "surveyVersion",
        headerName: "Version",
        minWidth: 20,
        flex: flexNoneCol,
        valueGetter: ({ row }) => row?.surveyVersion?.name,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "updatedAt",
        headerName: "Last Updated",
        minWidth: 75,
        maxWidth: 210,
        flex: flexMediumCol,
        valueFormatter: ({ value }) =>
          new Date(value as string)?.toLocaleString(),
      },
      {
        field: "createdAt",
        headerName: "Created",
        minWidth: 75,
        maxWidth: 210,
        flex: flexMediumCol,
        valueFormatter: ({ value }) =>
          new Date(value as string)?.toLocaleString(),
      },
    ],
    [navigate, downloadPdf, handleCsv]
  );

  if (!data) {
    return null;
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingCsv || loadingPDF}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className="page-title">
        <Typography variant="h2">{survey.title}</Typography>
      </Box>
      <Box
        onClick={() =>
          exportAllToCSV({
            variables: {
              filter: {
                isCompleted: true,
                survey: {
                  id: surveyId,
                },
              },
            },
          })
        }
        sx={{
          textAlign: "right",
        }}
      >
        <Button>Export all submitted forms</Button>
      </Box>
      <div className="data-wrapper">
        <DataGrid
          autoHeight
          disableColumnMenu={false}
          rows={data as any[]}
          columns={columns}
          editMode="row"
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          hideFooter={false}
          hideFooterSelectedRowCount={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: "isCompleted",
                    operatorValue: "equals",
                    value: "Submitted",
                  },
                ],
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              alignItems: "stretch !important",
              backgroundColor: "#ebfffe",
              color: "#078ca9",
              textOverflow: "clip",
              whiteSpace: "break-spaces",
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default AdminResponseList;
