import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import SettingIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';

import UserContext from '../../lib/UserContext';
import logo from '../../assets/perennia-app-bar.png';
import * as _ from 'lodash';

import './styles.css';


interface AppBarProps {
  toggleNav: () => void;
}

const AppBar: React.FC<AppBarProps> = ({ toggleNav }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);

  const [anchorSettings, setAnchorSettings] =
    React.useState<null | HTMLElement>(null);
  const openSettingsMenu = Boolean(anchorSettings);
  const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSettings(event.currentTarget);
  };
  const handleSettingsClose = (destination?: string | undefined) => {
    setAnchorSettings(null);
    if (destination) navigate(destination);
  };

  return (
    <React.Fragment>
      <MuiAppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/*Display the logo on the left when the screen is big enough*/}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img className="app-bar-logo" src={logo} alt="Perennia" onClick={() => navigate('/')}/>
            </Typography>

            {/*Display a hamburger when the view port is small*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleSettingsOpen}
                color="inherit"
              >
                <MenuIcon/>
              </IconButton>
            </Box>

            {/*Show the logo in the center of the app bar when the viewport is small*/}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: "right" }}
            >
              <img className="app-bar-logo" src={logo} alt="Perennia"/>
            </Typography>

            {/* Show the pages as links directly on the app bar when the page is big enough*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Box
                sx={{
                  pl: '1em',
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  borderLeft: '1px solid white',
                  fontSize: '0.675em',
                }}
              >
                {!user && (
                  <React.Fragment>
                    <Button
                      onClick={() => navigate('/login')}
                      sx={{ my: 1, color: 'white', display: 'block' }}
                    >
                      Login
                    </Button>
                    <Menu
                      id="settings-menu"
                      anchorEl={anchorSettings}
                      open={openSettingsMenu}
                      onClose={() => handleSettingsClose()}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleSettingsClose('/login');
                        }}
                      >
                        <LoginIcon
                          className="settings-icon"
                          fontSize="small"
                          color="primary"
                        />
                        Login
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
                {user && (
                  <React.Fragment>
                    <Button
                      onClick={() => {
                        navigate('/settings/profile');
                      }}
                      sx={{ my: 1, color: 'white', display: 'block' }}
                    >
                      {_.get(user, 'email')}
                    </Button>
                    <Box
                      //  onClick={handleOpenNavMenu}
                      sx={{ p: 0, ml: 2, mt: '8px', mb: '8px' }}
                    >
                      <IconButton onClick={handleSettingsOpen}>
                        <SettingIcon
                          className="settings-icon"
                          fontSize="small"
                        />
                      </IconButton>
                      <Menu
                        id="settings-menu"
                        anchorEl={anchorSettings}
                        open={openSettingsMenu}
                        onClose={() => handleSettingsClose()}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleSettingsClose('/');

                          }}
                        >
                          <HomeIcon
                            className="settings-icon"
                            fontSize="small"
                            color="primary"
                          />
                          Home
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSettingsClose('/submissions');

                          }}
                        >
                          <ArticleIcon
                            className="settings-icon"
                            fontSize="small"
                            color="primary"
                          />
                          My Submissions
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSettingsClose('/settings/profile');

                          }}
                        >
                          <PersonIcon
                            className="settings-icon"
                            fontSize="small"
                            color="primary"
                          />
                          My Profile
                          {/*{_.get(user, 'name')}*/}
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleSettingsClose();
                            logout().then(() => navigate('/'));
                          }}

                        >
                          <LogoutIcon
                            className="settings-icon"
                            fontSize="small"
                            color="primary"
                          />
                          Logout
                        </MenuItem>
                        {user.isSuperAdmin && [
                          <Divider key="superadmin-divider"/>,
                          <MenuItem
                            key="superadmin-item"
                            onClick={() =>
                              handleSettingsClose('/admin/forms')
                            }
                          >
                            <AdminPanelSettingsIcon
                              className="settings-icon"
                              fontSize="small"
                              color="primary"
                            />
                            Admin Portal
                          </MenuItem>,
                        ]}
                      </Menu>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>
      <Toolbar/>
    </React.Fragment>
  );
};
export default AppBar;
