import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, CircularProgress, Dialog } from '@mui/material';

import { useAuth } from './lib/Cognito';
import UserContext from './lib/UserContext';
import SurveyPage from './components/pages/SurveyPage';
import LandingPage from './components/pages/LandingPage';
import LoginPage from './components/pages/LoginPage';
import PageLayout from './components/PageLayout';
import './App.css';
import AdminFormsPage from "./components/pages/AdminFormsPage";
import AdminVersionsPage from "./components/pages/AdminVersionsPage";
import AdminFormCreatorContainer from "./components/AdminFormCreator";
import AdminResponsesPage from "./components/pages/AdminResponsesPage";
import ResponsePage from "./components/pages/ResponsePage";
import UserSettingsPage from './components/SettingsPages/UserSettingsPage';
import UserSubmissionsPage from './components/pages/UserSubmissionsPage';
import ResumePage from "./components/pages/ResumePage";

function App() {
  const { user, userLoading, authenticate, logout, refreshSession } = useAuth();

  return (
    <UserContext.Provider value={{ user, userLoading, logout, authenticate, refreshSession }}>
      <Dialog open={userLoading}>
        <Box sx={{ p: '3em' }}>
          <CircularProgress/>
        </Box>
      </Dialog>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/signup" element={<LoginPage/>}/>
          <Route path="/forgot-password" element={<LoginPage />} />
          <Route path="/reset-password" element={<LoginPage />} />
          <Route path="/" element={<PageLayout/>}>
            <Route path="apply/:formShortName" element={<SurveyPage/>}/>
            <Route path="admin/forms/:surveyId/:surveyVersionId" element={<AdminFormCreatorContainer/>}/>
            <Route index element={<LandingPage/>}/>
            <Route path="submissions" element={<UserSubmissionsPage/>}/>
            <Route path="submission/:responseId" element={<ResponsePage/>}/>
            <Route path="form/:formShortName" element={<SurveyPage/>}/>
            <Route path="resume" element={<ResumePage/>}/>
            <Route path="settings/profile" element={<UserSettingsPage/>}/>
          </Route>
          <Route path="/" element={<PageLayout adminOnly={true}/>}>
            <Route path="admin/response/:responseId" element={<ResponsePage/>}/>
            <Route path="admin/responses/:surveyId" element={<AdminResponsesPage/>}/>
            <Route path="admin/forms/:surveyId" element={<AdminVersionsPage/>}/>
            <Route path="admin/forms" element={<AdminFormsPage/>}/>
          </Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
