import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import AppSyncConfig from '../config/AppSyncConfig';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import React from 'react';
import { getCurrentUserToken } from './Cognito';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

function getAppSyncAuth(): AuthOptions {
  switch (AppSyncConfig.authenticationType) {
    case AUTH_TYPE.API_KEY:
      return {
        type: AppSyncConfig.authenticationType,
        apiKey: AppSyncConfig.apiKey!,
      };
    case AUTH_TYPE.OPENID_CONNECT:
      return {
        type: AppSyncConfig.authenticationType,
        jwtToken: () => '',
      };
    case AUTH_TYPE.AMAZON_COGNITO_USER_POOLS:
      return {
        type: AppSyncConfig.authenticationType,
        jwtToken: async () => getCurrentUserToken(),
      };
    default:
      return {
        type: AUTH_TYPE.NONE,
      };
  }
}

const httpLink = createHttpLink({ uri: AppSyncConfig.graphqlEndpoint });

let amplifyAuthLink: ApolloLink | null = null;

export const resetAuthLink= () => {
  amplifyAuthLink = null
}
const cachedAmplifyAuthLink = setContext(() => {
  if (amplifyAuthLink) {
    return { amplifyAuthLink };
  }

  // Asynchronously initialise and cache amplifyAuthLink.
  return getCurrentUserToken()
    .then((session) => {
      amplifyAuthLink = createAuthLink({
        url: AppSyncConfig.graphqlEndpoint!,
        region: AppSyncConfig.region!,
        auth: getAppSyncAuth(),
      });
      return { amplifyAuthLink };
    })
    .catch((error) => {
      // Amplify throws when not signed in.
      amplifyAuthLink = createAuthLink({
        url: AppSyncConfig.graphqlEndpoint!,
        region: AppSyncConfig.region!,
        auth: {
          type: AUTH_TYPE.API_KEY,
          apiKey: AppSyncConfig.apiKey!,
        },
      });
      return { amplifyAuthLink };
    });
});


const resetToken = onError(({ networkError }) => {
  if (networkError?.name === "ServerError") { //} && networkError?.statusCode === 401) {
    amplifyAuthLink = createAuthLink({
      url: AppSyncConfig.graphqlEndpoint!,
      region: AppSyncConfig.region!,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: AppSyncConfig.apiKey!,
      },
    });
  }
});


export const createAuthLinkv2 = (appSyncConfig: any) => {
  return cachedAmplifyAuthLink.concat(
    new ApolloLink((operation, forward) =>
      operation.getContext().amplifyAuthLink.request(operation, forward)
    )
    // resetToken
  ).concat(
    resetToken
  );
};


const link = ApolloLink.from([
  // createAuthLink({
  //   url: AppSyncConfig.graphqlEndpoint!,
  //   region: AppSyncConfig.region!,
  //   auth: getAppSyncAuth(),
  // }),
  new RetryLink(),
  createAuthLinkv2(AppSyncConfig),
  // resetToken,
  createSubscriptionHandshakeLink(
    {
      url: AppSyncConfig.graphqlEndpoint!,
      region: AppSyncConfig.region!,
      auth: getAppSyncAuth(),
    },
    httpLink
  ),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const AppSyncProvider: React.FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default AppSyncProvider;
