import * as SurveyPDF from "survey-pdf";
import { Response } from "../../generated/graphql";

export const exportPDF = (data: Response) => {
  const surveyPDF = new SurveyPDF.SurveyPDF(data?.surveyVersion?.config, {
    orientation: "p",
    format: "letter",
    commercial: true,
    fontName: "Helvetica",
    fontSize: 10,
    readonlyRenderAs: "auto",
    margins: {
      top: 20,
      bot: 10,
      left: 20,
      right: 20,
    },
  });
  surveyPDF.mode = "display";
  surveyPDF.data = JSON.parse(data.data as string);
  surveyPDF.save(`${data?.id}.pdf`);
};
