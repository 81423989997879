import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { navStructure } from './data';
import NavBar from './Bar';
import NavPage from './Page';

export { NavBar, NavPage };

export function usePageTitle() {
  const location = useLocation();

  return useMemo(() => {
    for (const item of navStructure) {
      if ('link' in item && item.link === location.pathname) {
        return item.name;
      }
      if ('items' in item) {
        const subItem = item.items.find(
          (subItem) => subItem.link === location.pathname
        );
        // if (subItem) return `${item.name} > ${subItem.name}`;  // REMOVE
        if (subItem) return subItem.name;
      }
    }
  }, [location]);
}
