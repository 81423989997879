import React, { useEffect } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import '../../styles/dialog.css'

interface AlertProps {
  title: string;
  msg: string;
  isOpen: boolean;
  onClose: () => void;
}


export const AlertDialog: React.FC<AlertProps> = ({ onClose, title, msg, isOpen }) => {
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen])

  return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {msg}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
}