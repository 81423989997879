import React from 'react';
import { useGetSurveysQuery, Survey } from '../../generated/graphql';
import SurveyList from './SurveyList';

interface Props {
  archived?: boolean
  active?: boolean
}

const SurveyListContainer = (props: Props) => {
  const { archived, active } = props;

  const { data, error, loading } = useGetSurveysQuery({
    variables: {
      active: !!active,
      archived: !!archived
    }
  })

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  return data ? <SurveyList data={data?.getSurveys as Survey[]} /> : <></>;
};

export default SurveyListContainer;
