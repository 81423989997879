import _ from 'lodash';

const ITEM_NAME: string = `perenniaforms-${window.location.hostname}`;

export class SessionStore {
  static setAuthedResponseId(shortname: string, responseId: string) {
    this.setUnauthedResponseId(shortname, responseId, true)
  };

  static setUnauthedResponseId(shortname: string, responseId: string, auth?: boolean) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify({
      ...data, ...{
        [shortname]: {
          responseId,
          auth: auth || false
        }
      }
    }));
  };

  static getResponseId(shortname: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    return _.get(data, `${shortname}.responseId`);
  };

  static getAuthStatus(shortname: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    return _.get(data, `${shortname}.auth`);
  };

  static setResponseState(responseId: string, state: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    const shortname = _.findKey(data, x => x.responseId === responseId);

    if (!shortname)
      return

    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify({
      ...data, ...{
        [shortname]: {
          ...data[shortname], ...{ state }
        }
      }
    }));
  }

  static getResponseState(responseId: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    const shortname = _.findKey(data, x => x.responseId === responseId);
    return _.get(data, `${shortname}.state`);
  }

  static setResponsePage(responseId: string, page: string | number) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    const shortname = _.findKey(data, x => x.responseId === responseId);

    if (!shortname)
      return

    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify({
      ...data, ...{
        [shortname]: {
          ...data[shortname], ...{ page: Number(page) }
        }
      }
    }));
  }

  static clearResponsePage(responseId: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    const shortname  = _.findKey(data, x => x.responseId === responseId);

    if (!shortname)
      return
    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify({
      ...data, ...{
        [shortname]: _.omit(data[shortname], 'page')
      }
    }));
  };

  static getResponsePage(responseId: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    const shortname = _.findKey(data, x => x.responseId === responseId);
    return _.get(data, `${shortname}.page`);
  }

  static clearResponseId(shortname: string) {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify(_.omit(data, shortname)));
  };

  static clearAuthedResponses() {
    const data = JSON.parse(window.sessionStorage.getItem(ITEM_NAME) || "{}");
    window.sessionStorage.setItem(ITEM_NAME, JSON.stringify(_.pickBy(data, x => !x.auth)));
  };

}