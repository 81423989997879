import React from 'react';
import ResponseRendered from './ResponseRendered';
import { useGetResponseWithConfigQuery } from "../../generated/graphql";
import { CircularProgress } from "@mui/material";

interface Props {
  responseId: string | undefined;
}

const ResponseContainer = (props: Props) => {
  const { responseId } = props;

  const { data, loading, error } = useGetResponseWithConfigQuery({
    variables: {
      id: responseId as string
    }
  });

  if (loading) {
    return <div><CircularProgress/></div>;
  }

  if (error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  return data ?
    <ResponseRendered
      model={JSON.parse(data?.getResponse?.surveyVersion?.config || "{}")}
      data={JSON.parse(data?.getResponse?.data || "{}")}
      surveyId={data?.getResponse?.survey?.id || ''}
    /> : <></>;
};

export default ResponseContainer;
