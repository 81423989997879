import { createContext } from 'react';
import { CurrentUser } from './Cognito';

interface IUserContext {
  user: CurrentUser | null;
  logout: () => Promise<void>;
  userLoading: boolean;
  authenticate: (email: string, password: string) => Promise<any>;
  refreshSession: () => void;
}

const defaultContext = {
  user: null,
  logout: async () => {},
  userLoading: true,
  authenticate: async () => {},
  refreshSession: () => {},
};

const UserContext = createContext<IUserContext>(defaultContext);

export default UserContext;
