import {
  PostAdd as PostAddIcon,
  // Storage as StorageIcon,
  // Settings as SettingsIcon,
  AdminPanelSettings as AdminPanelIcon,
  // DynamicForm as DynamicFormIcon,
  // Analytics as AnalyticsIcon,
  SvgIconComponent,
} from '@mui/icons-material';

export interface NavDivider {
  divider: true;
}

export interface NavItemLeaf {
  name: string;
  link: string;
  icon?: SvgIconComponent;
  image?: string;
  auth?: boolean;
}

export interface NavListParent {
  name: string;
  items: NavItemLeaf[];
  icon?: SvgIconComponent;
  link?: string;
  defaultOpen?: boolean;
  auth?: boolean;
}

export type NavItem = NavItemLeaf | NavListParent | NavDivider;

export const navStructure: NavItem[] = [
  {
    name: 'Profile',
    link: '/settings/profile',
    icon: PostAddIcon,
    auth: true
  },
  {
  name: 'Forms',
    link: '/',
    icon: PostAddIcon,
    auth: false
  },
  {
    name: 'Admin',
    icon: AdminPanelIcon,
    link: '/admin/forms',
    auth: true
  }
];
