import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import AppBar from './AppBar';
import {
  // NavBar,
  usePageTitle,
} from './Nav';
import UserContext from '../lib/UserContext';

interface Props {
  adminOnly?: boolean;
}

const PageLayout: React.FC<Props> = ({ adminOnly }: Props) => {
  const { refreshSession, user } = useContext(UserContext);
  let location = useLocation();
  const [showNav, setShowNav] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  useEffect(() => {
    // The nav is always shown when screen is wide enough. This will reset it to
    // be hidden when the window is subsequently made small again
    if (matches && showNav)
      setShowNav(false);
  }, [matches, setShowNav, showNav]);

  React.useEffect(refreshSession, [location, refreshSession]);

  useEffect( () => {
    if (adminOnly && !(user && user?.isSuperAdmin))
        navigate('/')
  }, [adminOnly, user, navigate])

  const title = usePageTitle();

  if (adminOnly && !(user && user?.isSuperAdmin))
    return <></>

  return (
    <div>
      <AppBar toggleNav={() => setShowNav(!showNav)}/>
      {title && (
        <Toolbar
          sx={{ background: '#cfdf16', ml: '1px', p: '1' }}
          className="tool-bar"
        ></Toolbar>
      )}
      <Stack direction={'row'}>
        {/*<NavBar show={showNav} />*/}

        <Container maxWidth={false} disableGutters id="bread-crumb">
          {/*<Typography variant="h3" sx={{ ml: 5, mt: 5 }}>*/}
          {/*{title}*/}
          {/*</Typography>*/}

          <Outlet/>
        </Container>
      </Stack>
    </div>
  );
};

export default PageLayout;
