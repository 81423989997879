import * as React from 'react';
import Box from '@mui/material/Box';

import '../SettingsPageStructure/styles.css';
import SettingNavigation from '../SettingsPageStructure/SettingNavigation';

import UserSettingsDetails from '../Settings/UserSettingsDetails';
import { useGetCurrentUserQuery } from "../../generated/graphql"


const UserSettings: React.FC = () => {

  const {data} = useGetCurrentUserQuery();

  if (!data) return <></>;

  const settingType = 'User'; // manually set
  const backLabel = ''


  return (
    <React.Fragment>
      <Box
        sx={{ color: 'primary.main' }}
        maxWidth="lg"
        className="landing-page"
      >
        <Box className="landing-page-content">
          <Box
            width={{
              md: 800,
              lg: 1200,
            }}
            margin="auto"
            sx={{p:1}}            
            className={`setting-container ${settingType.toLocaleLowerCase()}`}
          >
            <SettingNavigation
              settingType={settingType}
              backLabel={backLabel}
              handleNavClick={() => {}}
              activeTab={''}
              backUrl={'/'}
            />
            <Box
              className="settings-content"
              sx={{
                border: 1,
                borderColor: '#BBBBBB',
              }}
            >
                 <UserSettingsDetails data={data} />

             </Box>{' '}
            {/* .settings-content */}
          </Box>
        </Box>{' '}
        {/* .landing-page-content */}
      </Box>{' '}
      {/* .landing-page */}
    </React.Fragment>
  );
};
export default UserSettings;
