import * as React from 'react';

import '../../ApplicationPages/styles.css';
import ResponseContainer from '../../ResponseRendered';
import { useParams } from "react-router-dom";

interface Props {
}

const ResponsePage: React.FC = (props: Props) => {
  const urlParams = useParams();

  return (
    <ResponseContainer responseId={urlParams.responseId}/>
  );
};
export default ResponsePage;
