import React from 'react';
import { MemoizedCreatorAdmin } from './AdminFormCreator';
import { useGetSurveyVersionAdminQuery, SurveyVersion } from "../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_SURVEY_VERSION_ADMIN } from "../AdminSurveyVersionList/query";
import HeadingBar from "../HeadingBar";
import { Button } from "@mui/material";

interface Props {
}

const AdminFormCreatorContainer = (props: Props) => {
  const urlParams = useParams();
  const { surveyId, surveyVersionId } = urlParams;
  const navigate = useNavigate();

  const { data, error, loading } = useGetSurveyVersionAdminQuery({
    variables: {
      surveyVersionId: surveyVersionId || ''
    }
  });

  const [doSurveySave, { error: saving_error }] = useMutation(MUTATION_UPDATE_SURVEY_VERSION_ADMIN);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || saving_error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  if (data?.getSurveyVersion) {
    const surveyVersion = data?.getSurveyVersion as SurveyVersion;
    return data ? <div>
        <HeadingBar
          text="Survey Editor"
          rightItem={<Button onClick={() => navigate(`/admin/forms/${surveyId}`)}>Back to Version Manager</Button>}
        />
        <MemoizedCreatorAdmin data={surveyVersion} onSave={doSurveySave}/>
      </div>
      : <></>;
  }

  return <div>No survey found.</div>;
};

export default AdminFormCreatorContainer;
