import React from 'react';
import { useGetAllSurveyVersionsAdminQuery } from '../../generated/graphql';
import AdminSurveyVersionList from './AdminSurveyVersionList';

interface Props {
  surveyId: string
}

const AdminSurveyListContainer = (props: Props) => {
  const { surveyId } = props;

  const { data, error, loading, refetch } = useGetAllSurveyVersionsAdminQuery({
    variables: {
      surveyId
    }
  })

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  return data ? <AdminSurveyVersionList data={data} refetch={refetch} /> : <></>;
};

export default AdminSurveyListContainer;
