import { gql } from '@apollo/client';

export const QUERY_GET_ALL_SURVEY_VERSIONS_ADMIN = gql`
    query getAllSurveyVersionsAdmin($surveyId: ID!) {
        getSurvey(id: $surveyId) {
            id
            title
            publishedVersion {
                id
                name
            }
        }
        getSurveyVersions(filter: {surveyId: $surveyId}) {
            id
            name
            config
            updatedAt
        }
    }
`;

export const MUTATION_PUBLISH_SURVEY_VERSION_ADMIN = gql`
    mutation publishSurveyVersion($surveyVersionId: ID!) {
        publishSurveyVersion(id: $surveyVersionId) {
            id
            updatedAt
        }
    }
`;

export const MUTATION_UNPUBLISH_SURVEY_VERSION_ADMIN = gql`
    mutation UnpublishSurveyVersion($surveyVersionId: ID!) {
        unpublishSurveyVersion(id: $surveyVersionId)
    }
`;

export const MUTATION_UPDATE_SURVEY_VERSION_ADMIN = gql`
    mutation updateSurveyVersion($surveyVersionId: ID!, $input: SurveyVersionInput!) {
        updateSurveyVersion(id: $surveyVersionId, input: $input) {
            id
            name
            config
        }
    }
`;

export const MUTATION_CLONE_SURVEY_VERSION_ADMIN = gql`
    mutation createSurveyVersion($input: SurveyVersionInput!) {
        createSurveyVersion(input: $input) {
            id
            name
            config
        }
    }
`;
