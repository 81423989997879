import * as React from 'react';
import { useEffect } from 'react';

import './styles.css';
import { Button, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { MUTATION_CREATE_NEW_SURVEY_ADMIN } from "./query";
import { faker } from '@faker-js/faker';
import * as _ from 'lodash';

interface Props {
  refetch?: () => any;
}

const AdminAddNewSurvey: React.FC<Props> = (props: Props) => {

  const { refetch } = props;

  const [doCreateSurveyMutation, { loading: creating }] = useMutation(MUTATION_CREATE_NEW_SURVEY_ADMIN);

  const color = faker.commerce.color();
  const fish = faker.animal.fish();

  useEffect(() => {
    if (!creating && refetch)
      refetch();
  }, [creating, refetch]);

  return <div className={"survey-form-create-button"}>
    <Button color={"info"} onClick={() => doCreateSurveyMutation({
      variables: {
        input: {
          shortname: `${_.snakeCase(color)}-${_.snakeCase(fish)}`.split('_').join('-'),
          title: `${_.capitalize(color)} ${_.capitalize(fish)}`
        }
      }
    })}>
      <Typography variant={'h3'}>
        Create new form
      </Typography>
    </Button>
  </div>;
};

export default AdminAddNewSurvey;
