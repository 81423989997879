import * as React from 'react';
import {
  Toolbar,
  Typography,
  Divider,
} from '@mui/material';

import './styles.css';
import theme from "../../theme";

interface AppBarProps {
  text: string | undefined | null;
  rightItem?: any;
}

const HeadingBar: React.FC<AppBarProps> = (props) => {
  const { text, rightItem } = props;

  return (
    <React.Fragment>
      <Toolbar className="heading-bar"
               sx={{ background: theme.palette.grey.A100, ml: '1px', p: '1rem' }}
      >
        <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
          {text}
        </Typography>
        {rightItem}
      </Toolbar>
      <Divider/>
    </React.Fragment>
  );
};
export default HeadingBar;
