import * as React from 'react';

import '../../ApplicationPages/styles.css';
import SurveyContainer from '../../SurveyRendered';
import { useParams } from "react-router-dom"

interface Props {
}

const SurveyPage: React.FC = (props: Props) => {
  const urlParams = useParams();

  return (
    <SurveyContainer formShortName={urlParams.formShortName} />
  );
};
export default SurveyPage;
