import { gql } from '@apollo/client';

export const QUERY_GET_ALL_SURVEYS_ADMIN = gql`
    query getAllSurveysAdmin {
        getSurveys {
            id
            savable
            active
            inactiveMessage
            archived
            shortname
            title
            description
            publishedVersion {
                id
                name
                config
            }
        }
    }
`;


export const MUTATION_UPDATE_SURVEY_ADMIN = gql`
    mutation updateSurveyAdmin($surveyId: ID!, $input: SurveyInput!) {
        updateSurvey(id: $surveyId, input: $input) {
            id
            savable
            active
            archived
            shortname
            title
            description
        }
    }
`;
