import * as React from 'react';
import { useState, useEffect } from 'react';

import './styles.css';
import { GetAllSurveyVersionsAdminQuery } from '../../generated/graphql';
import { useNavigate } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { Box, Button, Switch, Typography } from "@mui/material";
import {
  MUTATION_CLONE_SURVEY_VERSION_ADMIN,
  MUTATION_PUBLISH_SURVEY_VERSION_ADMIN,
  MUTATION_UNPUBLISH_SURVEY_VERSION_ADMIN,
  MUTATION_UPDATE_SURVEY_VERSION_ADMIN
} from "./query";
import { useMutation } from "@apollo/client";
import EditCell, { EditCellVariant } from "../EditCell";


interface Props {
  data: GetAllSurveyVersionsAdminQuery;
  refetch: () => any;
}


const AdminSurveyVersionList: React.FC<Props> = (props: Props) => {
  const { data, refetch } = props;
  const navigate = useNavigate();
  const { getSurvey: survey, getSurveyVersions: versions } = data;
  const [publishedSurveyVersionId, setPublishedSurveyVersionId] = useState(survey?.publishedVersion?.id);

  const [doPublishVersion, { data: publishedData }] = useMutation(MUTATION_PUBLISH_SURVEY_VERSION_ADMIN);
  const [doUnpublishVersion, { data: unpublishedData }] = useMutation(MUTATION_UNPUBLISH_SURVEY_VERSION_ADMIN);
  const [doCloneVersion, { loading: cloning }] = useMutation(MUTATION_CLONE_SURVEY_VERSION_ADMIN);
  const [doUpdateVersion] = useMutation(MUTATION_UPDATE_SURVEY_VERSION_ADMIN);

  useEffect(() => {
    if (!cloning)
      refetch();
  }, [cloning, refetch]);

  useEffect(() => {
    if (publishedSurveyVersionId && unpublishedData?.unpublishSurveyVersion) {
      setPublishedSurveyVersionId(undefined);
    } else {
      if (publishedData) {
        setPublishedSurveyVersionId(publishedData?.publishSurveyVersion?.id as string);
      }
    }
  }, [publishedData, unpublishedData, publishedSurveyVersionId]);


  if (!survey || !versions) {
    return null;
  }


  return (
    <div>
      <Box className="page-title">
        <Typography variant="h2">{survey.title}</Typography>
      </Box>
      <TableContainer className="data-wrapper">
        <Box sx={{ border: 1, padding: 2 }}>
          <Table className="data-table">
            <TableHead>
              <TableRow className="table-header">
                <TableCell>Publish</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                _.orderBy(versions, ['updatedAt'], ['desc']).map(version => {
                  return (
                    <TableRow key={version?.id}>
                      <TableCell>
                        {publishedSurveyVersionId === version?.id ?
                          <Switch checked={true} color="success" onChange={(ev) => doUnpublishVersion({
                            variables: {
                              surveyVersionId: version?.id as string
                            }
                          })}/> :
                          <Switch checked={false} onChange={(ev) => doPublishVersion({
                            variables: {
                              surveyVersionId: version?.id as string
                            }
                          })}/>
                        }
                      </TableCell>
                      <TableCell>
                        <EditCell
                          onChange={() => {
                          }}
                          savedValue={version?.name}
                          onSave={(name) => doUpdateVersion({
                            variables: {
                              surveyVersionId: version?.id,
                              input: {
                                name: name as string
                              }
                            }
                          })}
                          noSave={false}
                          variant={EditCellVariant.TEXT}
                          autoFocus={true}
                          options={[]}
                        />
                      </TableCell>
                      <TableCell>
                        {version?.id}
                      </TableCell>
                      <TableCell>{version?.updatedAt ? (new Date(
                        version?.updatedAt)).toLocaleString() : undefined} </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => navigate(`/admin/forms/${survey.id}/${version?.id}`)}>
                          <Typography variant={'h3'}>
                            Edit
                          </Typography>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => doCloneVersion({
                            variables: {
                              input: {
                                surveyId: survey?.id,
                                name: version?.name ? `${version?.name} Copy` : '',
                                config: version?.config || {}
                              }
                            }
                          })}>
                          <Typography variant={'h3'}>
                            Clone
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </div>
  );
};

export default AdminSurveyVersionList;
