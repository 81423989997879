import React, { useContext } from 'react';
import { useGetMyResponsesQuery, Response } from '../../generated/graphql';
import UserContext from "../../lib/UserContext";
import * as SurveyPDF from "survey-pdf";
import { Box, Tooltip, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  PictureAsPdf as PdfIcon,
  Preview as PreviewIcon,
  Edit as EditIcon
} from "@mui/icons-material";
import { SessionStore } from "../../lib/SessionStore";

// interface Props {
// }

const UserSubmissions: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const { data } = useGetMyResponsesQuery({
    variables: {
      filter: {
        user: user?.email?.toLowerCase()
      }
    }
  });

  if (!user || !data)
    return <></>;

  const { getResponses: responses } = data;

  const doEdit = (formShortName: string, submissionId: string) => {
    SessionStore.setAuthedResponseId(formShortName, submissionId)
    navigate(`/form/${formShortName}`);
  };
  const downloadPdf = (response: Response) => {
    const surveyPDF = new SurveyPDF.SurveyPDF(
      response?.surveyVersion?.config,
      {
        orientation: 'p', format: 'letter',
        commercial: true,
        fontName: 'Helvetica', fontSize: 10,
        readonlyRenderAs: 'auto',
        margins: {
          top: 20,
          bot: 10,
          left: 20,
          right: 20
        }
      }
    );
    surveyPDF.mode = 'display';
    surveyPDF.data = JSON.parse(response?.data as string);
    surveyPDF.save(`${response?.id}.pdf`);
  };

  return (<React.Fragment>
      <Box className="page-title">
        {/*  <Typography variant="h2">My Submissions</Typography>*/}
      </Box>
      <div className="data-wrapper">
        <TableContainer>
          <Table className="data-table">
            <TableHead>
              <TableRow className="table-header">
                <TableCell>
                  <Typography variant={'h3'}>
                    Form
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'h3'}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'h3'}>
                    Actions
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'h3'}>
                    Last Updated
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={'h3'}>
                    Created
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                _.orderBy(responses, 'updatedAt', ['desc'])?.filter(response => response?.data !== '{}').map(response => {
                  return (
                    <TableRow key={response?.id}>
                      <TableCell>
                        <Tooltip title={`Submission ID: ${response?.id}`}>
                          <span>{response?.survey?.title}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {
                          response?.isCompleted ? "Submitted" : "Draft"
                        }
                      </TableCell>
                      <TableCell>
                        {
                          !response?.isCompleted ?
                            <Tooltip title={"Edit"}>
                              <EditIcon
                                onClick={() => doEdit(response?.survey?.shortname as string, response?.id as string)}
                              />
                            </Tooltip> :
                            <></>
                        }
                        <Tooltip title={"View"}>
                          <PreviewIcon onClick={() => navigate(`/submission/${response?.id}`)}/>
                        </Tooltip>
                        <Tooltip title={"Download"}>
                          <PdfIcon onClick={() => downloadPdf(response as Response)}/>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {new Date(response?.updatedAt as string)?.toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {new Date(response?.createdAt as string).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};


export default UserSubmissions;