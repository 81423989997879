import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { usePrevious } from '../../lib/hooks';
import {
  Replay as RevertIcon,
  Save as SaveIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';

import './styles.css';

export enum EditCellVariant {
  NUMBER,
  SELECT,
  TEXT,
  TEXTAREA,
  NONE,
}

interface EditCellProps {
  keyName?: any;
  savedValue: any;
  onChange?: (newValue: any) => void | any;
  changed?: boolean;
  onSave: (newValue: any) => Promise<void> | Promise<any>;
  onEdit?: () => any;
  editing?: boolean;
  noSave: boolean;
  autoFocus: boolean;
  inputId?: string;
  format?: (value: any) => string;
  variant: EditCellVariant;
  options: any[];
  addEmptyOption?: boolean;
  onReset?: (value: any, savedValue: any) => void | any;
  textColor?: string;
}

const EditCell: React.FC<EditCellProps> = ({
                                             keyName,
                                             savedValue,
                                             options,
                                             addEmptyOption,
                                             onChange,
                                             changed: changedProp,
                                             onSave,
                                             variant = EditCellVariant.TEXT,
                                             noSave,
                                             autoFocus = true,
                                             inputId,
                                             format,
                                             onReset,
                                             onEdit,
                                             editing,
                                             textColor,
                                           }) => {
  const previousKey = usePrevious(keyName);
  const previouslySavedValue = usePrevious(savedValue);
  const [_editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, _setValue] = useState(savedValue);
  const changed =
    variant === EditCellVariant.NONE
      ? !!changedProp
      : !_.isEqual(value, savedValue) && !(!value && !savedValue);

  const setValue = useCallback(
    (v: any) => {
      _setValue(v);
      _.isFunction(onChange) && onChange(v);
    },
    [_setValue, onChange]
  );

  const reset = useCallback(() => {
    const _value = value;
    setEditing(false);
    setValue(savedValue);
    onReset && onReset(_value, savedValue);
  }, [value, savedValue, onReset, setValue]);

  useEffect(() => {
    if (keyName !== previousKey || savedValue !== previouslySavedValue) {
      reset();
    }
  }, [keyName, savedValue, previousKey, previouslySavedValue, reset]);

  const save = async () => {
    setSaving(true);
    try {
      if (variant === EditCellVariant.SELECT && addEmptyOption) {
        await onSave(value || null);
      } else {
        await onSave(value);
      }
    } catch (e) {
      console.log(`Error saving value: ${e}`);
      setEditing(changed);
    }
    setEditing(false);
    setSaving(false);
  };
  const edit = async () => {
    try {
      onEdit && (await onEdit());
      setEditing(true);
    } catch (e) {
      console.log(`Error calling onEdit: ${e}`);
    }
  };

  const icons = saving ? (
    <CircularProgress size={32} />
  ) : (
    <div style={{ display: 'inline-block' }} className="saving-icons">
      <SaveIcon
        fontSize="medium"
        className={`edit-cell-icon edit-cell-icon${changed ? '' : '-disabled'}`}
        style={{ marginLeft: '5px', color: changed ? 'green' : '' }}
        onClick={save}
      />
      <RevertIcon
        fontSize="medium"
        className={`edit-cell-icon`}
        style={{ color: 'crimson' }}
        onClick={reset}
      />
    </div>
  );
  const formField = (() => {
    switch (variant) {
      case EditCellVariant.TEXT:
        return (
          <TextField
            id={inputId}
            disabled={saving}
            onKeyDown={(ev) =>
              ['Esc', 'Escape'].includes(ev.key) ? reset() : null
            }
            autoFocus={autoFocus}
            className="form-control text-control"
            value={value || ''}
            onChange={(ev) => setValue(ev.target.value)}
          />
        );
      case EditCellVariant.NUMBER:
        return (
          <TextField
            id={inputId}
            type="number"
            disabled={saving}
            onKeyDown={(ev) =>
              ['Esc', 'Escape'].includes(ev.key) ? reset() : null
            }
            autoFocus={autoFocus}
            className="form-control number-control"
            value={value || ''}
            onChange={(ev) => setValue(ev.target.value)}
          />
        );
      case EditCellVariant.TEXTAREA:
        return (
          <textarea
            id={inputId}
            disabled={saving}
            onKeyDown={(ev) =>
              ['Esc', 'Escape'].includes(ev.key) ? reset() : null
            }
            autoFocus={autoFocus}
            className="form-control textarea-control"
            value={value || ''}
            onChange={(ev) => setValue(ev.target.value)}
            style={{ width: 'calc(100% - 96px)' }}
          />
        );
      case EditCellVariant.SELECT:
        return (
          <select
            disabled={saving}
            onKeyDown={(ev) =>
              ['Esc', 'Escape'].includes(ev.key) ? reset() : null
            }
            className="form-control edit-cell select-control"
            value={value || ''}
            onChange={(ev) => setValue(ev.target.value)}
            // style={{ margin: '5px' }}
          >
            {addEmptyOption && <option key="nullValue" value=""></option>}
            {options.map((s) => (
              <option value={_.get(s, 'value', s)} key={_.get(s, 'value', s)}>
                {format ? format(_.get(s, 'label', s)) : _.get(s, 'label', s)}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  })();

  if (editing !== undefined ? editing : _editing) {
    return (
      <div className="form-inline edit-cell">
        {formField}
        <div
          style={{
            width: '96',
            display: noSave ? 'none' : 'inline-block',
            position: 'relative',
          }}
          className="edit-cell-confirm"
        >
          {icons}
        </div>
      </div>
    );
  }

  if (variant === EditCellVariant.NONE) {
    return (
      <div className="form-inline">
        <div
          style={{
            width: '145',
            display: noSave ? 'none' : 'inline-block',
            position: 'relative',
          }}
          className="edit-cell-confirm"
        >
          <div>
            {!saving && (
              <EditIcon
                fontSize="medium"
                className={`edit-cell-icon`}
                style={{ marginLeft: '5px' }}
                onClick={edit}
              />
            )}
            {changed && icons}
          </div>
        </div>
      </div>
    );
  }

  const getLabelFromOptions = (value: string) => {
    if (typeof options[0] === 'string') {
      return value;
    }
    const labels = options.filter((o) => String(o.value) === String(value));
    return !labels.length ? '' : labels[0].label;
  };

  return (
    <div className={'edit-cell-editable-field '} onClick={edit}>
      <span className={textColor}>
        {variant === EditCellVariant.SELECT
          ? format
            ? format(getLabelFromOptions(value))
            : getLabelFromOptions(value)
          : format
            ? format(value)
            : value}
      </span>
      <div key="edit-cell-pencil-div" className="edit-cell-edit-show">
        <EditIcon fontSize="medium" className="edit-cell-edit-pencil" />
      </div>
    </div>
  );
};

export default EditCell;
