import React, { useEffect } from "react";
import {
  // useGetResponsesQuery,
  Response,
  // useGetSurveyQuery,
  Survey,
} from "../../generated/graphql";
import AdminResponseList from "./AdminResponseList";
import {
  QUERY_GET_ALL_RESPONSES,
  MUTATION_EXPORT_ALL_CSV,
  QUERY_GET_SURVEY,
} from "./query";
import { useQuery, useMutation } from "@apollo/client";

interface Props {
  surveyId: string;
}

const AdminResponseListContainer = (props: Props) => {
  const { surveyId } = props;

  const {
    data: surveyData,
    error: surveyError,
    loading: surveyLoading,
  } = useQuery(QUERY_GET_SURVEY, {
    variables: {
      id: surveyId,
    },
  });

  const { data, error, loading } = useQuery(QUERY_GET_ALL_RESPONSES, {
    variables: {
      filter: {
        survey: {
          id: surveyId,
        },
      },
    },
  });

  const [exportAllToCSV, { data: csvData }] = useMutation(
    MUTATION_EXPORT_ALL_CSV
  );

  useEffect(() => {
    if (csvData) {
      let link = document.createElement("a");
      link.href = csvData.exportAllToCSV;
      link.setAttribute("visibility", "hidden");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData]);

  if (loading || surveyLoading) {
    return <div>Loading...</div>;
  }

  if (error || surveyError) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  return data ? (
    <AdminResponseList
      survey={surveyData?.getSurvey as Survey}
      data={data?.getResponsesMetadata as Response[]}
      exportAllToCSV={exportAllToCSV}
      surveyId={surveyId}
    />
  ) : (
    <></>
  );
};

export default AdminResponseListContainer;
