import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles.css';
import SurveyListContainer from "../../SurveyList"

// import Divider from '@mui/material/Divider';

const LandingPage: React.FC = () => {
  return (
    <React.Fragment>
      <Box
        sx={{ color: 'primary.main' }}
        maxWidth="lg"
        className="landing-page"
      >
        <Box className="landing-page-banner">
          <Typography variant="h2">Available Forms</Typography>
        </Box>
        <Box className="landing-page-context">
          <SurveyListContainer archived={false} active={true}/>
        </Box>

        {/*<Box className="landing-page-banner">*/}
        {/*  <Typography variant="h2">Inactive Forms</Typography>*/}
        {/*</Box>*/}
        {/*<Box className="landing-page-context">*/}
        {/*  <SurveyListContainer archived={false} active={false}/>*/}
        {/*</Box>*/}
      </Box>
    </React.Fragment>
  );
};

export default LandingPage;
