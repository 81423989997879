import * as React from 'react';
import Box from '@mui/material/Box';

import './styles.css';
import AdminSurveyListContainer from "../../AdminSurveyList"
import HeadingBar from "../../HeadingBar";

// import Divider from '@mui/material/Divider';

const AdminFormsPage: React.FC = () => {
  return (
    <React.Fragment>
      <Box
        sx={{ color: 'primary.main' }}
        maxWidth="lg"
        className="landing-page"
      >
        <HeadingBar
          text="Forms Manager"
        />
        <Box className="landing-page-context">
          <AdminSurveyListContainer />
        </Box>

      </Box>
    </React.Fragment>
  );
};

export default AdminFormsPage;
