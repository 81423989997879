import * as React from 'react';
import Box from '@mui/material/Box';

import './styles.css';
import AdminSurveyVersionListContainer from "../../AdminSurveyVersionList"
import { useNavigate, useParams } from "react-router-dom";
import HeadingBar from "../../HeadingBar";
import { Button, Typography } from "@mui/material";

// import Divider from '@mui/material/Divider';

const AdminVersionsPage: React.FC = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{ color: 'primary.main' }}
        maxWidth="lg"
        className="landing-page"
      >
        <HeadingBar
          text="Form Version Manager"
          rightItem={
          <Button onClick={() => navigate('/admin/forms')}>
            <Typography variant={'h3'}>
              Back to Forms
            </Typography>
          </Button>
          }
          />
        <Box className="landing-page-context">
          <AdminSurveyVersionListContainer surveyId={urlParams.surveyId as string}/>
        </Box>

      </Box>
    </React.Fragment>
  );
};

export default AdminVersionsPage;
