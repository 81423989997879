import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  settingType: string;
  settingFor: string;
  showNewButton?: string | boolean;
  addNewFunction?: () => void;
  nUsers?: number | string;
  orgId?: number | string;
}

const SettingHeader = (props: Props) => {
  const {
    settingFor,
    showNewButton,
    addNewFunction,
  } = props;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-between"

      bgcolor="#F2F2F2"
      sx={{ py: 2, px: 1 }}
    >
      <Box 
        sx={{ order:1, alignSelf: 'center' }
      }>
        <Typography
          variant="h3"
          sx={{

            color: '#1976D2',
          }}
        >
          {settingFor}
        </Typography>
      </Box>       


      {showNewButton && (
        <Box

          justifyContent="flex-end"
          alignSelf="center"
          width="35%"
          sx={{ order:4, alignSelf:"center", textAlign:"right" }}
        >
          <Button
            startIcon={<AddIcon />}
            onClick={() => addNewFunction && addNewFunction()}
            color="secondary"
            size="large"
            sx={{
              border: 1,
              borderColor: '#333',
              borderStyle: 'dashed',
              borderRadius: 0,
              py: 1,
            }}
          >
            New {showNewButton}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SettingHeader;
