import React, { useCallback, useContext, useEffect, useState } from 'react';
import SurveyRendered from './SurveyRendered';
import { Survey, useGetSurveyDataByShortnameQuery } from "../../generated/graphql";
import { MUTATION_CREATE_RESPONSE, MUTATION_UPDATE_RESPONSE, QUERY_GET_RESPONSE } from "./query";
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, CircularProgress, Dialog, Typography } from "@mui/material";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import HeadingBar from "../HeadingBar";
import { ExitDialog } from "./ExitDialog";
import { SessionStore } from "../../lib/SessionStore";
import UserContext from "../../lib/UserContext";

interface Props {
  formShortName: string | undefined;
}

const SurveyContainer = (props: Props) => {
  const { formShortName } = props;
  const [client, setClient] = useState<string | null>();
  const [showInactive, setShowInactive] = useState(false);
  const [responseData, setResponseData] = useState<string | null>();
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [wipData] = useState([{}]);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const { data: dataResponse, loading: loadingResponse } = useQuery(QUERY_GET_RESPONSE, {
    variables: {
      id: client
    }
  });

  const [doCreateResponse, {
    data: createdResponse,
    loading: createResponseLoading
  }] = useMutation(MUTATION_CREATE_RESPONSE);
  const [doUpdateResponse, {
    data: _updatedResponseData,
    loading: updateResponseLoading
  }] = useMutation(MUTATION_UPDATE_RESPONSE);


  const { data: dataModel, error, loading } = useGetSurveyDataByShortnameQuery({
    variables: {
      shortname: formShortName
    }
  });

  useEffect(() => {
    if (!updateResponseLoading && _updatedResponseData)
      setResponseData(_updatedResponseData.updateResponse.data);
  }, [updateResponseLoading, _updatedResponseData, setResponseData]);

  useEffect(() => {
    if (!loadingResponse && dataResponse?.getResponse?.data) {
      if (!dataResponse?.getResponse?.isCompleted) {
        // navigate(`/submission/${SessionStore.getResponseId(formShortName as string)}`)
        // } else {
        setResponseData(dataResponse.getResponse.data);
      }
    }
  }, [setResponseData, responseData, dataResponse, loadingResponse, navigate, formShortName]);

  useEffect(() => {
    if (!createResponseLoading && createdResponse?.createResponse?.id) {
      SessionStore.setUnauthedResponseId(formShortName as string, createdResponse?.createResponse?.id);
      setClient(SessionStore.getResponseId(formShortName as string));
    }
  }, [createdResponse, formShortName, createResponseLoading, setClient]);

  const createResponse = useCallback(async (survey: Survey) => {
    await doCreateResponse({
      variables: {
        input: {
          survey: {
            id: survey.id
          },
          surveyVersion: {
            id: survey.publishedVersion?.id
          }
        }
      }
    });
  }, [doCreateResponse]);

  useEffect(() => {
    if (dataModel && dataModel?.getSurveys) {
      const survey = dataModel?.getSurveys[0] as Survey;
      if (!SessionStore.getResponseId(formShortName as string)) {
        if (!survey.active) {
          console.log("This is an inactive survey; we can't start new");
          setShowInactive(true);
        } else {
          createResponse(survey);
        }
      } else {
        if (!survey.active) {
          console.log("Continuing an inactive survey");
        }
        const uuid: string | undefined = SessionStore.getResponseId(formShortName as string);
        const auth: string | undefined = SessionStore.getAuthStatus(formShortName as string);
        if (uuid && (!auth || (auth && user.user))) {
          console.log("Setting uuid");
          setClient(uuid);
        } else {
          console.log("Creating new response");
          createResponse(survey);
        }
      }

    }
  }, [createResponse, dataModel, formShortName, user.user]);

  if (loading || updateResponseLoading) {
    return <Dialog open={true}><Box sx={{ p: '3em' }}><CircularProgress size={32}/></Box></Dialog>;
  }

  if (error) {
    return <div>Error loading data: {`${error}`}</div>;
  }

  if (!dataModel || !dataModel?.getSurveys) {
    return <div>No survey found.</div>;
  }

  const survey = dataModel?.getSurveys[0] as Survey;

  if (showInactive) {
    return <div>
      <HeadingBar text={survey.title}
      />
      <Box display="flex"
           justifyContent="center"
           alignItems="center"
           minHeight="50vh"
      >
        <Typography variant="h3">
          {_.get(survey, 'inactiveMessage', 'This form is not available.')}
        </Typography>
      </Box>
    </div>;
  }

  if (!SessionStore.getResponseId(formShortName as string)) {
    return <Dialog open={true}><Box sx={{ p: '3em' }}><CircularProgress size={32}/></Box></Dialog>;
  }

  const clearClientId = () => {
    SessionStore.clearResponseId(formShortName as string);
  };

  const confirmedExit = async () => {
    if (client && SessionStore.getResponseId(client))
      await saveResponse(wipData[0]);

    clearClientId();
    navigate("/");
  };

  const cancelExit = () => {
    setShowExitDialog(false);
  };

  const exitForm = async () => {
    if (client && (client === SessionStore.getResponseId(formShortName as string)))
      await saveResponse(wipData[0]);
    setShowExitDialog(true);
  };

  const completeResponse = async (response: object) => {
    await doUpdateResponse({
      variables: {
        id: client,
        input: {
          data: JSON.stringify(response),
          isCompleted: true,
          user: _.get(response, 'email')
        },
      }
    });
  };

  const saveResponse = async (response: object) => {
    await doUpdateResponse({
      variables: {
        id: client,
        input: {
          data: JSON.stringify(response),
          user: _.get(response, 'email')
        },
      }
    });
  };

  const saveWip = async (response: object) => {
    wipData[0] = response;
  };

  return (
    <div>
      <HeadingBar
        text={survey.title}
        rightItem={<Button onClick={() => exitForm()}><Typography variant={'h3'}>
          {survey.savable ? 'Save and exit' : 'Exit this form'}
        </Typography></Button>}
      />
      <Dialog open={showExitDialog} onClose={() => setShowExitDialog(false)}>
        <ExitDialog
          warningMessage={survey.savable ?
            'Please check your e-mail for a link to retrieve your submission.' :
            ''
          }
          onSuccess={() => {
            confirmedExit();
          }}
          onCancel={() => {
            cancelExit();
          }}
        />
      </Dialog>
      {dataModel ? <SurveyRendered model={JSON.parse(survey.publishedVersion?.config || "{}")}
                                   data={JSON.parse(responseData || "{}")}
                                   onSave={saveResponse}
                                   onComplete={completeResponse}
                                   clientId={client || ''}
                                   onUpdateWip={saveWip}
      /> : <></>}
    </div>);
};

export default SurveyContainer;

