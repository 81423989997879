import * as React from 'react';
import { useContext, useMemo } from "react";
import { Button, CircularProgress } from "@mui/material";
import HeadingBar from "../HeadingBar";
import { useLocation, useNavigate } from "react-router-dom";

import * as SurveyCore from "survey-core";
import * as SurveyReact from "survey-react-ui";

import './styles.css';
import "survey-core/modern.min.css";
import UserContext from "../../lib/UserContext";
import _ from 'lodash';

interface Props {
  model: {};
  data: {};
  surveyId: string;
}

SurveyCore
  .StylesManager
  .applyTheme("modern");

const ResponseRendered: React.FC<Props> = (props: Props) => {
  const { user } = useContext(UserContext);
  const { model, data, surveyId } = props;
  const survey = useMemo(() => new SurveyCore.Model(model), [model]);
  const navigate = useNavigate();
  const location = useLocation();

  if (!model || !data) {
    return <CircularProgress/>;
  }

  if (!user)
    return <></>;

  survey.data = data;
  survey.mode = 'display';


  return (
    <div>
      <HeadingBar
        text={survey.title}
        rightItem={<Button onClick={() => navigate(
          _.startsWith(location.pathname, '/admin/response/') ?
            `/admin/responses/${surveyId}` :
            `/submissions`
        )
        }>Back to Submissions</Button>}
      />
      <SurveyReact.Survey model={survey}/>
    </div>
  );
};

export default ResponseRendered;
