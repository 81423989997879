import React from 'react';
import { useEffect, useMemo } from "react";

import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { SurveyVersion } from "../../generated/graphql";

//Import Survey localization
import "survey-core/survey.i18n.js";
//Import Survey Creator localization
import "survey-creator-core/survey-creator-core.i18n.js";
// @ts-ignore
import * as widgets from "surveyjs-widgets";
import * as SurveyCore from "survey-core";


//Import Survey and Creator styles
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import './styles.css';

widgets.inputmask(SurveyCore);

interface Props {
  data: SurveyVersion;
  onSave: any;
}

const AdminFormCreator: React.FC<Props> = (props: Props) => {
  const { data, onSave } = props;

  const creator = useMemo(() => new SurveyCreator({
    showLogicTab: true,
    haveCommercialLicense: true,
    isAutoSave: true,
    showState: true,
    // showJSONEditorTab: false,
  }), []);

  useEffect(() => {
    creator.text = data.config || "{}";
  }, [data.config, creator]);

  if (!data) {
    return null;
  }
  const surveyVersionId = data.id;

  // const creator =
  // creator.isAutoSave = true;
  // creator.showState = true;


  const saveMySurvey = () => {
    onSave({
      variables: {
        surveyVersionId,
        input: {
          config: creator.text
        }
      }
    });
  };


  creator.saveSurveyFunc = saveMySurvey;

  return (
    <div className="creator-content">
      <SurveyCreatorComponent creator={creator}/>
    </div>
  );
};

// export default AdminFormCreator;
function propsAreEqual(prevProps: any, nextProps: any) {
  return true;
}

export const MemoizedCreatorAdmin = React.memo(AdminFormCreator, propsAreEqual);