import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  resendConfirmationEmail,
  confirmUserSignUp,
} from "../../../lib/Cognito";
import "./styles.css";

interface ResendConfirmationCodeProps {
  email: string;
  isSignUp: boolean;
  login: () => Promise<void>;
  close: () => void;
}

const ResendConfirmationCode: React.FC<ResendConfirmationCodeProps> = ({
  email,
  isSignUp,
  login,
  close,
}) => {
  const [verificationCode, setVerificationCode] = useState<string>("");

  useEffect(() => {
    const interval = setInterval(login || (() => {}), 3000);

    return () => clearInterval(interval);
  }, [login]);

  const handleVerify = async () => {
    try {
      await confirmUserSignUp(email, verificationCode);
      login && login();
      close && close();
    } catch (error) {
      console.error("Error confirming sign-up", error);
    }
  };

  const userPrompt = isSignUp
    ? "An email has been sent to you to activate your account. "
    : "Your e-mail needs to be verified. ";

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="close dialog"
        component="div"
        className="dialog-close-button"
        onClick={close}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ p: "4em", m: "2em" }}>
        <Stack spacing={3}>
          <Typography variant="h5">
            Activate your Perennia Online account
            <LinearProgress sx={{ height: "1px" }} />
          </Typography>
          <Typography>
            {userPrompt}
            Please check your email for a verification code from Perennia and
            enter it below.
          </Typography>
          {isSignUp && (
            <div>
              <Typography>Enter verification code:</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="verification-code"
                label="Verification code"
                name="verification-code"
                autoFocus
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button onClick={handleVerify} variant="outlined">
                Verify
              </Button>
            </div>
          )}
          <div>
            <Typography>Didn't receive one? </Typography>
            <Button
              onClick={() => resendConfirmationEmail(email)}
              variant="outlined"
            >
              Click to resend verification e-mail.
            </Button>
          </div>
        </Stack>
      </Box>
    </div>
  );
};

export default ResendConfirmationCode;
