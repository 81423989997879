import { ICognitoUserPoolData } from 'amazon-cognito-identity-js';

/*
	export interface ICognitoUserPoolData {
		UserPoolId: string;
		ClientId: string;
		endpoint?: string;
		Storage?: ICognitoStorage;
		AdvancedSecurityDataCollectionFlag?: boolean;
	}
 */

const CognitoConfig: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
};

if (process.env.REACT_APP_COGNITO_ENDPOINT) {
  CognitoConfig.endpoint = process.env.REACT_APP_COGNITO_ENDPOINT;
}

export default CognitoConfig;
