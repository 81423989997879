import { AUTH_TYPE } from 'aws-appsync-auth-link/lib/auth-link';

interface IAppSyncConfig {
  graphqlEndpoint?: string;
  region?: string;
  authenticationType?: AUTH_TYPE;
  apiKey?: string;
  jwtToken?: () => Promise<string>;
}

const authType =
  process.env.REACT_APP_APPSYNC_AUTH || 'AMAZON_COGNITO_USER_POOLS';

const AppSyncConfig: IAppSyncConfig = {
  graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  region: process.env.REACT_APP_AWS_REGION,
  authenticationType: AUTH_TYPE[authType as keyof typeof AUTH_TYPE],
  apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
};

export default AppSyncConfig;
