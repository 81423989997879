import React from 'react';

import './styles.css';
import '../../styles/table.css';
import { Survey } from '../../generated/graphql';
import { Link } from "react-router-dom";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import EditCell, { EditCellVariant } from "../EditCell";
import { MUTATION_UPDATE_SURVEY_ADMIN } from "./query";
import { useMutation } from "@apollo/client";
import {
  Info as InfoIcon,
  Insights as InsightsIcon,
  Preview as PreviewIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import AdminAddNewSurvey from "../AdminAddNewSurvey/AdminAddNewSurvey";


interface Props {
  data: Survey[];
  refetch: () => any;
}

const AdminSurveyList: React.FC<Props> = (props: Props) => {
  const { data, refetch } = props;
  const [doUpdateSurvey] = useMutation(MUTATION_UPDATE_SURVEY_ADMIN);

  if (!data) {
    return null;
  }

  return (
    <div>
      <AdminAddNewSurvey refetch={refetch}/>
      <div className="data-wrapper">
        <Box sx={{ border: 1, padding: 2 }}>
          <TableContainer>
            <Table className="data-table">
              <TableHead>
                <TableRow className="table-header">
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Short Name</TableCell>
                  <TableCell align="left">Active</TableCell>
                  <TableCell align="left">Savable</TableCell>
                  <TableCell align="left">Archived</TableCell>
                  <TableCell align="left">Versions</TableCell>
                  <TableCell align="center">Responses</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  _.orderBy(data, 'shortname', ['asc'])?.map(form => {
                    return (
                      <TableRow key={form.id}>
                        <TableCell>
                          <EditCell
                            onChange={() => {
                            }}
                            savedValue={form.title}
                            onSave={(title) => doUpdateSurvey({
                              variables: {
                                surveyId: form.id,
                                input: {
                                  title
                                }
                              }
                            })}
                            noSave={false}
                            variant={EditCellVariant.TEXT}
                            autoFocus={true}
                            options={[]}
                          />
                        </TableCell>
                        <TableCell>
                          {form.active ?
                            <Typography sx={{ color: 'text.secondary' }}>
                              {form.shortname}
                              &nbsp;
                              <Link to={`/form/${form.shortname}`} target="_blank" className="center-icon">
                                <PreviewIcon/>
                              </Link>
                            </Typography>
                            :
                            <EditCell
                              onChange={() => {
                              }}
                              savedValue={form.shortname}
                              onSave={(shortname) => doUpdateSurvey({
                                variables: {
                                  surveyId: form.id,
                                  input: {
                                    shortname
                                  }
                                }
                              })}
                              noSave={false}
                              variant={EditCellVariant.TEXT}
                              autoFocus={true}
                              options={[]}
                            />
                          }
                        </TableCell>
                        <TableCell>
                          <Switch checked={!!form.active} color={form.active ? 'success' : 'primary'}
                                  onChange={() => doUpdateSurvey({
                                    variables: {
                                      surveyId: form.id,
                                      input: {
                                        active: !form.active
                                      }
                                    }
                                  })}
                                  disabled={!form?.publishedVersion?.id}
                          />
                          {form.active ? <></> :
                            (form?.inactiveMessage ?
                            <Tooltip title={
                              form?.inactiveMessage ?
                                `Users will see the following message: ${form.inactiveMessage}` :
                                "Click to customize user notification when accessing an inactive form."}><span>
                            <InfoIcon
                              sx={{ verticalAlign: 'middle' }}
                            />
                            </span>
                            </Tooltip> :  <></>)
                          }
                        </TableCell>
                        <TableCell>
                          <Switch checked={!!form.savable} color={form.savable ? 'success' : 'primary'}
                                  onChange={() => doUpdateSurvey({
                                    variables: {
                                      surveyId: form.id,
                                      input: {
                                        savable: !form.savable
                                      }
                                    }
                                  })}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch checked={!!form.archived} color={form.archived ? 'success' : 'primary'}
                                  onChange={() => doUpdateSurvey({
                                    variables: {
                                      surveyId: form.id,
                                      input: {
                                        archived: !form.archived
                                      }
                                    }
                                  })}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Link to={`/admin/forms/${form.id}`} className="center-icon">
                            <SettingsIcon/>
                          </Link>
                          &nbsp;
                          {form.publishedVersion?.name ? form.publishedVersion?.name :
                            (form.publishedVersion ? `${form.publishedVersion?.id.slice(0, 8)}...` : undefined)
                          }
                        </TableCell>
                        <TableCell align="center">
                          <Link to={`/admin/responses/${form.id}`} className="center-icon">
                            <InsightsIcon/>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
};

export default AdminSurveyList;
