import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import SuccessIcon from '@mui/icons-material/CheckCircleOutline';

import { GetCurrentUserQuery, User } from '../../generated/graphql';

import SettingHeader from '../SettingsPageStructure/SettingHeader';
import EditCell, { EditCellVariant } from '../EditCell';
import { MUTATION_UPDATE_SELF } from './query';
import { useMutation } from '@apollo/client';
import { changeUserPassword, UnauthorizedError } from '../../lib/Cognito';

interface Props {
  data?: GetCurrentUserQuery;
}

const UserSettingsDetails: React.FC<Props> = ({ data }) => {
  const [user, setUser] = useState<User | undefined>(undefined);

  const [doUpdateSelf] = useMutation(MUTATION_UPDATE_SELF);
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const [showChangePasswordSuccess, setShowChangePasswordSuccess] =
    useState(false);

  const resetErrors = useCallback(() => {
    setOldPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
  }, [setOldPasswordError, setNewPasswordError, setConfirmPasswordError]);


  useEffect(() => {
    if (showChangePasswordSuccess) {
      setTimeout(() => setShowChangePasswordSuccess(false), 2500);
    }
  }, [showChangePasswordSuccess, setShowChangePasswordSuccess]);

  useEffect(() => {
    if (data) {
      const { getCurrentUser: _user } = data;
      setUser(_user as User);
    }
  }, [data]);

  if (!user) return <CircularProgress />;

  const updateUser = async (newData: Object) => {
    await doUpdateSelf({
      variables: {
        id: user?.id as number,
        input: newData,
      },
    });
  };

  const doChangePassword = async () => {
    let error = false;
    if (!oldPassword.trim() || !newPassword.trim()) {
      if (!newPassword.trim()) {
        setNewPasswordError(' ');
        setConfirmPasswordError('Required');
      }
      !oldPassword.trim() && setOldPasswordError('Required');
      error = true;
    }

    if (!!newPassword.trim() && newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords must match');
      error = true;
    }

    if (newPassword.trim().length < 8) {
      setNewPasswordError('Password must be at least 8 characters');
      error = true;
    }

    if (error) {
      return;
    }

    try {
      resetErrors();
      setChangingPassword(true);
      await changeUserPassword(user.email, oldPassword, newPassword);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowChangePasswordSuccess(true);
    }
    catch (e) {
      if (e instanceof UnauthorizedError) {
        setOldPasswordError('Incorrect password');
      }
    }
    finally {
      setChangingPassword(false);
    }
  };

  return (
    <React.Fragment>
      <Box className="setting-panel">
        <SettingHeader settingType={'users'} settingFor={'User Profile'} />
        <TableContainer>
          <Table className="data-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '200px' }}>User Information</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={user?.id}>
                <TableCell>Name</TableCell>
                <TableCell>
                  <EditCell
                    key={`name-${user?.id}`}
                    savedValue={user?.name}
                    onSave={(v) => updateUser({ name: v })}
                    noSave={false}
                    variant={EditCellVariant.TEXT}
                    autoFocus={true}
                    options={[]}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{user?.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer sx={{ mt: 3 }}>
          <Table className="data-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '200px' }}>
                  Password
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Stack
                    spacing={1}
                    direction="column"
                    sx={{ width: 'fit-content' }}
                    // justifyContent="center"
                    // alignItems="center"
                  >
                    <TextField
                      id="oldPassword"
                      label="Current Password"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      size="small"
                      sx={{ width: 'fit-content' }}
                      type="password"
                      value={oldPassword}
                      helperText={oldPasswordError.trim()}
                      error={!!oldPasswordError}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        resetErrors();
                        setOldPassword(ev.target.value);
                      }}
                    />
                    <Stack direction="column" spacing={2} alignItems="baseline">
                      <TextField
                        id="newPassword"
                        label="New Password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        type="password"
                        value={newPassword}
                        helperText={newPasswordError.trim()}
                        error={!!newPasswordError}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          resetErrors();
                          setNewPassword(ev.target.value);
                        }}
                      />
                      <TextField
                        id="confirmPassword"
                        label="Confirm New Password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        size="small"
                        type="password"
                        value={confirmPassword}
                        helperText={confirmPasswordError.trim()}
                        error={!!confirmPasswordError}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          resetErrors();
                          setConfirmPassword(ev.target.value);
                        }}
                      />
                    </Stack>
                    <Divider />
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ p: 1, width: 'fit-content' }}
                      onClick={doChangePassword}
                      disabled={changingPassword}
                    >
                      {changingPassword && (
                        <CircularProgress size={18} sx={{ mr: '5px' }} />
                      )}
                      {showChangePasswordSuccess && (
                        <SuccessIcon fontSize="small" sx={{ mr: '5px' }} />
                      )}
                      Save Password
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>


      </Box>
    </React.Fragment>
  );
};

export default UserSettingsDetails;
