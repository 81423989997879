import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

import DividerWithText from "../../DividerWithText";
import {
  createUser,
  IncorrectCodeException,
  InvalidCodeException,
  requestUserPasswordResetCode,
  resetUserPassword,
  UnauthorizedError,
  UnconfirmedEmailError,
  UserExistsError,
  WeakPasswordError,
} from "../../../lib/Cognito";
import UserContext from "../../../lib/UserContext";
import ResendConfirmationCode from "./ResendConfirmationCode";

import "./styles.css";
import logo from "../../../assets/perennia-app-bar.png";

export enum LoginPageMode {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
}

const LoginPagePaths: { [path: string]: LoginPageMode } = {
  "/login": LoginPageMode.LOGIN,
  "/signup": LoginPageMode.SIGNUP,
  "/forgot-password": LoginPageMode.FORGOT_PASSWORD,
  "/reset-password": LoginPageMode.RESET_PASSWORD,
};

const DialogTitles = {
  [LoginPageMode.LOGIN]: "Sign In",
  [LoginPageMode.SIGNUP]: "Sign Up",
  [LoginPageMode.FORGOT_PASSWORD]: "Forgot Password",
  [LoginPageMode.RESET_PASSWORD]: "Reset Password",
};

const ButtonText = {
  [LoginPageMode.LOGIN]: "Login",
  [LoginPageMode.SIGNUP]: "Sign Up",
  [LoginPageMode.FORGOT_PASSWORD]: "Send Password Reset Code",
  [LoginPageMode.RESET_PASSWORD]: "Reset Password",
};

const DividerText = {
  [LoginPageMode.LOGIN]: "Not Registered?",
  [LoginPageMode.SIGNUP]: "Already have an account?",
  [LoginPageMode.FORGOT_PASSWORD]: "Remembered your password?",
  [LoginPageMode.RESET_PASSWORD]: "Remembered your password?",
};

//TODO: figure out how to make variable sessions
const useRememberMe = false;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useState(new URLSearchParams(location.search));
  const [showPassword, setShowPassword] = useState(false);
  const [mode, setMode] = useState(
    LoginPagePaths[location.pathname] || LoginPageMode.LOGIN
  );
  const [email, setEmail] = useState(queryParams.get("email") || "");
  const [showEmailConfirmationDialog, setShowEmailConfirmationDialog] =
    useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [name, setName] = useState(queryParams.get("name") || "");
  const [nameError, setNameError] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const { user, userLoading, authenticate } = useContext(UserContext);

  useEffect(() => {
    resetErrors();
    const desiredPath = Object.entries(LoginPagePaths).find(
      ([path, pathMode]) => pathMode === mode
    );

    if (desiredPath && location.pathname !== desiredPath[0]) {
      navigate(desiredPath[0], { replace: true });
    }
  }, [navigate, mode, location.pathname]);

  const resetErrors = () => {
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setNameError("");
    setCodeError("");
  };

  const doSignUp = async () => {
    let error = false;
    if (!email.trim() || !password.trim() || !name.trim()) {
      !email.trim() && setEmailError("Required");
      if (!password.trim()) {
        setPasswordError(" ");
        setConfirmPasswordError("Required");
      }
      !name.trim() && setNameError("Required");
      error = true;
    }

    if (!!password.trim() && password !== confirmPassword) {
      setConfirmPasswordError("Passwords must match");
      error = true;
    }

    if (password.trim().length < 8) {
      setPasswordError("Password must be at least 8 characters");
      error = true;
    }

    const nameParts = name.split(" ").map((part) => part.trim());
    if (
      nameParts.length < 2 ||
      _.max(nameParts.map((part) => part.length)) === 1
    ) {
      setNameError("Please enter your full name");
      error = true;
    }

    if (error) {
      return;
    }

    try {
      resetErrors();
      setLoggingIn(true);
      await createUser(email.trim(), password.trim(), name.trim());
      setShowEmailConfirmationDialog(true);
    } catch (e) {
      if (e instanceof UserExistsError) {
        setEmailError("A user with this email already exists");
        return;
      }
      if (e instanceof WeakPasswordError) {
        setPasswordError(e.message);
        return;
      }
    } finally {
      setLoggingIn(false);
    }
  };

  const doLogin = async () => {
    if (!email.trim() || !password.trim()) {
      !email.trim() && setEmailError("Required");
      !password.trim() && setPasswordError("Required");
      return;
    }
    try {
      resetErrors();
      setLoggingIn(true);
      await authenticate(email, password);
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        setEmailError(" ");
        setPasswordError(e.message);
        return;
      }
      if (e instanceof UnconfirmedEmailError) {
        setShowEmailConfirmationDialog(true);
        return;
      }
      console.log(e);
    } finally {
      setLoggingIn(false);
    }
  };

  const doForgotPassword = async () => {
    if (!email.trim()) {
      setEmailError("Required");
      return;
    }

    try {
      resetErrors();
      setLoggingIn(true);
      await requestUserPasswordResetCode(email);
      setMode(LoginPageMode.RESET_PASSWORD);
    } catch (e) {
      console.log(e);
    } finally {
      setLoggingIn(false);
    }
  };

  const doResetPassword = async () => {
    let error = false;
    if (!email.trim() || !password.trim() || !code.trim()) {
      !email.trim() && setEmailError("Required");
      if (!password.trim()) {
        setPasswordError(" ");
        setConfirmPasswordError("Required");
      }
      !code.trim() && setCodeError("Required");
      error = true;
    }

    if (!!password.trim() && password !== confirmPassword) {
      setConfirmPasswordError("Passwords must match");
      error = true;
    }

    if (password.trim().length < 8) {
      setPasswordError("Password must be at least 8 characters");
      error = true;
    }

    if (error) {
      return;
    }

    try {
      resetErrors();
      setLoggingIn(true);
      await resetUserPassword(email, password, code);
      await doLogin();
    } catch (e) {
      if (e instanceof IncorrectCodeException) {
        setCodeError(e.message);
        return;
      }
      if (e instanceof InvalidCodeException) {
        setEmailError(" ");
        setCodeError(e.message);
        return;
      }

      console.log(e);
    } finally {
      setLoggingIn(false);
    }
  };

  const doSubmit = async () => {
    switch (mode) {
      case LoginPageMode.LOGIN:
        return doLogin();
      case LoginPageMode.SIGNUP:
        return doSignUp();
      case LoginPageMode.FORGOT_PASSWORD:
        return doForgotPassword();
      case LoginPageMode.RESET_PASSWORD:
        return doResetPassword();
    }
  };

  const submitOnEnter = ({ key }: React.KeyboardEvent) => {
    if (key === "Enter") {
      return doSubmit();
    }
  };

  useEffect(() => {
    if (user) {
      if (queryParams.get("resume")) {
        navigate(
          `/resume?email=${user.email}&responseid=${queryParams.get(
            "resume"
          )}&shortname=${queryParams.get("shortname")}`
        );
      } else navigate("/");
    }
  }, [user, navigate, queryParams]);

  if (userLoading) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <Dialog
        open={showEmailConfirmationDialog}
        onClose={() => setShowEmailConfirmationDialog(false)}
      >
        <ResendConfirmationCode
          email={email}
          isSignUp={mode === LoginPageMode.SIGNUP}
          login={doLogin}
          close={() => setShowEmailConfirmationDialog(false)}
        />
      </Dialog>
      <Dialog open={loggingIn && !showEmailConfirmationDialog}>
        <Box sx={{ p: "3em" }}>
          <CircularProgress />
        </Box>
      </Dialog>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", sm: "flex" },
          height: "10em",
        }}
      ></Box>
      <Box
        className="login-page-form-container"
        sx={{ flexGrow: 1, p: "3em", m: "auto" }}
      >
        <Grid container spacing={2} sx={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <img alt="Perennia" className="login-page-logo" src={logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{DialogTitles[mode]}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ ml: "2em", mr: "2em" }}>
              <Stack spacing={4}>
                <Box>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    value={email}
                    disabled={!!email && queryParams.get("email") === email}
                    helperText={emailError.trim()}
                    error={!!emailError}
                    onKeyPress={submitOnEnter}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      resetErrors();
                      setEmail(ev.target.value);
                    }}
                  />
                  {mode !== LoginPageMode.FORGOT_PASSWORD && (
                    <TextField
                      id="password"
                      label="Password"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      value={password}
                      helperText={passwordError.trim()}
                      error={!!passwordError}
                      onKeyPress={submitOnEnter}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        resetErrors();
                        setPassword(ev.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position={"end"}
                            style={{
                              maxHeight: "none",
                              height: "auto",
                              marginTop: "-1px",
                              marginRight: "-1px",
                              marginBottom: "-1px",
                            }}
                          >
                            {!showPassword ? (
                              <VisibilityIcon
                                className="visibility-icon"
                                fontSize="small"
                                color="primary"
                                onClick={() => setShowPassword(true)}
                              />
                            ) : (
                              <VisibilityOffIcon
                                className="visibility-icon"
                                fontSize="small"
                                color="primary"
                                onClick={() => setShowPassword(false)}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {[
                    LoginPageMode.SIGNUP,
                    LoginPageMode.RESET_PASSWORD,
                  ].includes(mode) && (
                    <TextField
                      id="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      helperText={confirmPasswordError.trim()}
                      error={!!confirmPasswordError}
                      onKeyPress={submitOnEnter}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        resetErrors();
                        setConfirmPassword(ev.target.value);
                      }}
                    />
                  )}
                  {mode === LoginPageMode.SIGNUP && (
                    <TextField
                      id="name"
                      label="Full Name"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={name}
                      helperText={nameError.trim()}
                      error={!!nameError}
                      onKeyPress={submitOnEnter}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        resetErrors();
                        setName(ev.target.value);
                      }}
                    />
                  )}
                  {mode === LoginPageMode.RESET_PASSWORD && (
                    <TextField
                      id="code"
                      label="Reset Password Code"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      value={code}
                      helperText={codeError.trim()}
                      error={!!codeError}
                      onKeyPress={submitOnEnter}
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        resetErrors();
                        setCode(ev.target.value);
                      }}
                    />
                  )}
                  {mode === LoginPageMode.LOGIN && (
                    <Typography textAlign="right" sx={{ mt: "0.5rem" }}>
                      <Link
                        className="login-page-link"
                        onClick={() => setMode(LoginPageMode.FORGOT_PASSWORD)}
                      >
                        Forgot password?
                      </Link>
                    </Typography>
                  )}
                </Box>
                {useRememberMe && (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                )}
                <Box>
                  <Stack spacing={1}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ p: "0.75em" }}
                      onClick={doSubmit}
                    >
                      {ButtonText[mode]}
                    </Button>
                    <p />
                    <DividerWithText>{DividerText[mode]}</DividerWithText>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ p: "0.75em" }}
                      onClick={() =>
                        setMode(
                          mode === LoginPageMode.LOGIN
                            ? LoginPageMode.SIGNUP
                            : LoginPageMode.LOGIN
                        )
                      }
                    >
                      {mode === LoginPageMode.LOGIN
                        ? "Switch to Sign Up"
                        : "Switch to Login"}
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default LoginPage;
